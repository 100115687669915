/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import Img from 'gatsby-image/withIEPolyfill'
// eslint-disable-next-line import/no-extraneous-dependencies
import get from 'lodash/get'
// eslint-disable-next-line import/no-extraneous-dependencies
import clamp from 'lodash/clamp'
import useDimensions from 'react-use-dimensions'

import { Box, Flex } from '@components/Grid'
import { H4 } from '@components/Heading'
import { Text } from '@components/Text'
import { Blob } from '@components/Blob'
import styled from '@style'
import { ArrowLeft, ArrowRight } from './Arrow'

const QuoteText = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`

const ArrowContainer = styled(Flex)`
  background: hsla(255, 100%, 100%, 0.8);
  border-radius: 50% 50% 23% 77% / 57% 55% 45% 43%;
  transition: border-radius 250ms ease-in-out;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & + & {
    border-radius: 55% 45% 62% 38% / 35% 55% 45% 65%;
  }

  &:hover,
  &:focus {
    border-radius: 51% 49% 55% 45% / 47% 55% 45% 53%;
    background: hsla(255, 100%, 100%, 1);
  }
`

const Testimonials = ({ headline, content }) => {
  const [sizeRef, { height, width: textWidth }] = useDimensions()

  const [longestQuote, setLongestQuote] = useState({ _key: 'INITIAL' })
  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const blubb = content
    if (content.length) {
      setLongestQuote(blubb.sort((a, b) => b.quote.length - a.quote.length)[0])
    }
  }, [content])

  const index = useRef(0)
  const [springs, set] = useSprings(content.length, i => ({
    x: i * 468,
    sc: 1,
    o: i === index.current ? 1 : 0,
    display: i === index.current ? 'block' : 'none',
    // delay: key => (key === 'o' ? 2000 : 0),
  }))
  const bindGesture = useDrag(
    ({
      dragging,
      movement: [xMovement],
      direction: [xDir],
      distance,
      cancel,
    }) => {
      if (dragging && distance > textWidth / 2.5)
        cancel(
          (index.current = clamp(
            index.current + (xDir > 0 ? -1 : 1),
            0,
            content.length - 1
          ))
        )
      set(i => {
        // Hide all except for current +/- one
        if (i < index.current - 1 || i > index.current + 1)
          return { display: 'block' }
        const o = i === index.current ? 1.0 : 0.0
        const x = (i - index.current) * 468 + (dragging ? xMovement : 0)

        // const sc = dragging ? 1 - distance / window.innerWidth / 2 : 1
        const sc = 1
        return { x, sc, o, display: 'block' }
      })
    }
  )

  const refreshSprings = () => {
    set(i => {
      // Hide all except for current +/- one
      if (i < index.current - 1 || i > index.current + 1)
        return { display: 'block' }
      const x = (i - index.current) * 468
      const o = i === index.current ? 1.0 : 0.0
      return { x, o, display: 'block' }
    })
  }

  const goToPrevious = () => {
    index.current = clamp(index.current - 1, 0, content.length - 1)
    refreshSprings()
  }

  const goToNext = () => {
    index.current = clamp(index.current + 1, 0, content.length - 1)
    refreshSprings()
  }

  if (typeof content !== 'object') {
    return <p>Please define content</p>
  }

  return (
    <Box
      as="section"
      bg="blue.100"
      position="relative"
      // minHeight="400px"
      height={[`${height + 180}px`, `${height + 200}px`, `${height + 300}px`]}
      px={[5, 6, 7]}
      py={[6, 7, 8]}
      overflow="hidden"
      css={{ userSelect: 'none' }}
    >
      <Box maxWidth={512} mx="auto" position="relative" zIndex="2">
        <Blob
          position="absolute"
          top={['-32px']}
          display="block"
          width={['140%', '80%', '70%']}
          pb={['140%', '80%', '70%']}
          borderRadius="42% 58% 44% 56% / 66% 45% 55% 34%"
          css={{
            background:
              'linear-gradient( 210deg, hsla(255,100%,100%, 0.1), hsla(255,100%,100%, 1.0) )',
          }}
        />
        <Box mb={[7, 6]} position="relative">
          <H4 mb={7}>{headline}</H4>
          <Flex
            position="absolute"
            top="calc(100% + 8px)"
            right="0"
            width="100%"
            flexDirection="row"
            justifyContent="flex-end"
            height="40px"
          >
            <ArrowContainer
              onClick={() => goToPrevious()}
              alignItems="center"
              justifyContent="center"
              width="50px"
              height="40px"
              color="blue.600"
              mr={4}
            >
              <ArrowLeft />
            </ArrowContainer>
            <ArrowContainer
              onClick={() => goToNext()}
              alignItems="center"
              justifyContent="center"
              width="50px"
              height="40px"
              color="blue.600"
            >
              <ArrowRight />
            </ArrowContainer>
          </Flex>
        </Box>
        <Box display="block" ml={6} position="relative">
          <Box position="absolute" pr={[1, 2, 0]} right="100%" top="-16px">
            <Text
              zIndex="1"
              color="red.light"
              opacity={0.5}
              fontFamily="serif"
              fontWeight="bold"
              fontSize={['100px', '160px', '200px']}
              lineHeight={['0.9em', '0.87em', '0.81em']}
            >
              “
            </Text>
          </Box>
          <Box minHeight="200px" position="relative">
            {springs.map(({ display, x, o }, i) => (
              <React.Fragment key={i}>
                <QuoteText
                  ref={content[i]._key === longestQuote._key ? sizeRef : null}
                  {...bindGesture()}
                  style={{
                    display,
                    transform: x.to(y => `translate3d(${y}px,0,0)`),
                    opacity: o.to(p => p),
                  }}
                >
                  <Text zIndex="5" mb={4}>
                    {content[i].quote}
                  </Text>

                  <Flex ml={[-6, 0]} flexDirection="row" alignItems="center">
                    {get(content[i], 'portrait.asset.fixed', 'NO_IMG') !==
                    'NO_IMG' ? (
                      <Box
                        mr={4}
                        borderRadius="50%"
                        height="50px"
                        width="50px"
                        boxShadow="subtle"
                        overflow="hidden"
                      >
                        <Img
                          fixed={content[i].portrait.asset.fixed}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </Box>
                    ) : null}
                    <Text fontWeight="medium">{content[i].name}</Text>
                  </Flex>
                </QuoteText>
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Testimonials.propTypes = {
  headline: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quote: PropTypes.string.isRequired,
      portrait: PropTypes.shape({
        asset: PropTypes.shape({
          // eslint-disable-next-line react/forbid-prop-types
          fixed: PropTypes.any,
        }),
      }),
    })
  ).isRequired,
}

export { Testimonials }
