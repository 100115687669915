import React from 'react'
import styled from '@style'

const Left = props => (
  <svg width={31} height={16} viewBox="0 0 31 16" fill="none" {...props}>
    <path
      d="M0.292891 7.29289C-0.097633 7.68342 -0.097633 8.31658 0.292891 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292891 7.29289ZM31 7L0.999998 7L0.999998 9L31 9L31 7Z"
      fill="inherit"
    />
  </svg>
)

const Right = props => (
  <svg width={31} height={16} viewBox="0 0 31 16" fill="none" {...props}>
    <path
      d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9L30 9L30 7L0 7L0 9Z"
      fill="inherit"
    />
  </svg>
)

const ArrowLeft = styled(Left)`
  fill: currentColor;
`

const ArrowRight = styled(Right)`
  fill: currentColor;
`

export { ArrowLeft, ArrowRight }
