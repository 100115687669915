/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import padStart from 'lodash/padStart'
import get from 'lodash/get'

import { Box } from '@components/Grid'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { NewsletterSection } from '@components/NewsletterSection'
import { Hero } from '@components/Hero'
import { Topic } from '@components/Topic'
import { Testimonials } from '@components/Testimonials'
import { StrongDarkBackgroundWrapper } from '@components/Heading'
import { useScrollToElement } from '@hooks/useScrollToElement'

const KeynotesPage = ({ data: { content }, location }) => {
  // For scroll-to-button
  const targetRef = React.useRef(null)
  const { scrollToTarget } = useScrollToElement()

  return (
    <Layout>
      <SEO
        metadata={{
          title: content.seo.seoTitle,
          description: content.seo.seoDescription,
          pathname: location.pathname,
        }}
      />
      <Hero.VideoBackground>
        <Hero.Shape shape="wide" color="hsl(214,100%,97%)" />
        <Hero>
          <StrongDarkBackgroundWrapper>
            <Hero.Topline color="white">
              {content._rawHero.topline}
            </Hero.Topline>
            <Hero.Heading color="white" content={content._rawHero.heading} />
            <Hero.Text content={content._rawHero.text} color="white" />
            <Hero.Button
              mb={8}
              onClick={() => scrollToTarget(targetRef.current)}
            >
              {content._rawHero.cta.label}
              {content._rawHero.cta.showArrow && <Hero.Button.Arrow />}
            </Hero.Button>
          </StrongDarkBackgroundWrapper>
        </Hero>
      </Hero.VideoBackground>
      <Testimonials
        headline="Fascinating topics — customized to your needs"
        content={content.testimonials}
      />
      <Box ref={targetRef}>
        {content.topics.map((topic, index) => {
          const image = get(topic, 'image.asset.fluid', null)
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Topic
              key={topic._key}
              blobType={topic.blobType}
              variant={topic.variant}
              image={image}
            >
              <Topic.Topline>{`${padStart(
                index + 1,
                2,
                '0'
              )} — Keynote Topic`}</Topic.Topline>
              <Topic.Heading>{topic.title}</Topic.Heading>
              <Topic.Body>
                {topic._rawDescription ? (
                  <Topic.Description
                    content={topic._rawDescription}
                    fontWeight="medium"
                  />
                ) : null}
                {topic.contentList.length ? (
                  <Topic.List>
                    {topic.contentList.map((item, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Topic.Item key={`${item}-${i}`}>{item}</Topic.Item>
                    ))}
                  </Topic.List>
                ) : null}
              </Topic.Body>
            </Topic>
          )
        })}
      </Box>

      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query KeynotesPageQuery {
    content: sanityKeynotesPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHero
      testimonials {
        _key
        name
        portrait {
          asset {
            fixed(width: 100) {
              ...GatsbySanityImageFixed
            }
          }
        }
        quote
      }
      topics {
        _key
        variant
        title
        _rawDescription(resolveReferences: { maxDepth: 10 })
        contentList
        blobType
        image {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      hero {
        backgroundImage {
          asset {
            fluid(maxWidth: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default KeynotesPage
